export { matchers } from './client-matchers.js';

			export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')];

			export const server_loads = [0];

			export const dictionary = {
	"/": [~2],
	"/arkiv": [~4],
	"/bekraftelse-nyhetsbrev": [~5],
	"/bookmarks": [~7],
	"/book": [6],
	"/inspiration/[vignette]": [~8],
	"/om-oss": [~9],
	"/order/[orderId]": [~10],
	"/passengers": [11],
	"/privacy-policy": [12],
	"/purchase": [13],
	"/reseguider": [~17],
	"/reseguide/[location]": [~14],
	"/reseguide/[location]/[category]": [~15],
	"/reseguide/[location]/[category]/[place]": [~16],
	"/rss.xml": [~18],
	"/search/content/[query]": [~19],
	"/taxonomy/term/[...id]/feed": [~20],
	"/terms-and-conditions": [21],
	"/tv/[[slug]]/[videoserie]/[videoslug]": [~23],
	"/tv/[[slug]]": [~22],
	"/[...articleslug]": [~3]
};

			export const hooks = {
				handleError: (({ error }) => { console.error(error) }),
			};